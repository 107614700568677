.Layout {
  min-height: 100vh;
  width: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
  color: var(--title-color);
  padding: 2em 3em 1em 3em;
  background-color: #f3f8fe;
}
.Layout main {
  padding: 1rem 2rem;
}
.Layout .Footer {
  width: 100%;
}
@media (max-width: 960px) {
  .Layout main {
    padding: 0.5rem;
  }
}
@media (max-width: 600px) {
  .Layout {
    padding: 0.5em;
  }
}
.bgWrapper {
  position: a;
}
