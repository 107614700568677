.SKCard {
  border: none;
  outline: none;
  padding: 1rem 2vw;
  font-size: 0.9rem;
  background: var(--offer-color);
  border-radius: 12px;

  @media (max-width: 600px) {
    padding: 1rem;
  }
}
.reverse {
  background: #dbeafd;
}
